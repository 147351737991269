
// const toastTrigger = document.getElementById('liveToastBtn')
// const toastLiveExample = document.getElementById('liveToast')

// if (toastTrigger) {
//   const toastBootstrap = bootstrap.Toast.getOrCreateInstance(toastLiveExample)
//   toastTrigger.addEventListener('click', () => {
//     toastBootstrap.show()
//   })
// }

// $(document).ready(function(){

//     $("#cv-btn").click(function(){
             
//      showToast('This a very example toast. Cool, right?');
             
//     });
          
//   });
//  showToast('Message for notify goes here!', {
//     duration: 5000, // The time interval after notification disappear 
//     background: '#20b2aa', // Background color for toast notification 
//     color: '#f1f1f1', //Text color 
//     borderRadius: '15px' //Border Radius 
//   });

